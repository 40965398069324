import styled from "styled-components"

import { Link as GatsbyLink } from "gatsby"
import { Heading } from "@ballchy/styled-bits"
import { color, space, layout, flexbox, typography } from "styled-system"
import shouldForwardProp from "@styled-system/should-forward-prop"

export const Link = styled(GatsbyLink).withConfig({ shouldForwardProp })`
  ${flexbox}
  ${color}
  ${typography}
  ${space}
  ${layout}
  text-decoration: none;
  text-transform: uppercase;
  color: currentColor;

  :visited,:active {
    color: currentColor;
  }
`

Link.defaultProps = {
  display: "flex",
  justifyContent: "center",
}

export const Overlay = styled(Heading)`
  line-height: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -100;
  color: ${({ theme }) => theme.colors.overlay};
`
