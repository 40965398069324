import React, { useState, useContext, createContext, useEffect } from "react"
import { ThemeProvider as StyledThemeProvider } from "styled-components"
import merge from "lodash.merge"
import get from "lodash.get"
import StyledTheme from "./theme"

const Context = createContext()

export const useStore = () => useContext(Context)

// merge the color mode with the base theme
// to create a new theme object
const getTheme = mode =>
  merge({}, StyledTheme, {
    colors: get(StyledTheme.colors.modes, mode, StyledTheme.colors),
  })

export default props => {
  const [themeMode, setThemeMode] = useState(global.window?.__theme || "light")
  const toggleTheme = () => {
    window.__setPreferredTheme(themeMode === "light" ? "dark" : "light")
  }

  useEffect(() => {
    global.window.__onThemeChange = setThemeMode
  }, [])

  const theme = getTheme(themeMode)

  return (
    <Context.Provider value={{ mode: themeMode, toggleTheme }}>
      <StyledThemeProvider theme={theme}>{props.children}</StyledThemeProvider>
    </Context.Provider>
  )
}
