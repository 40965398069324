import React from "react"
import PropTypes from "prop-types"

import Header from "components/Header"
import { GlobalStyle } from "store/theme"
import Provider from "store/provider"

const Layout = ({ children }) => {
  return (
    <Provider>
      <GlobalStyle />
      <Header />
      <main>{children}</main>
    </Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
