import { createGlobalStyle } from "styled-components"

export default {
  breakpoints: ["40em", "52em", "64em"],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  colors: {
    body: "#2c3e50",
    heading: "#2c3e50",
    background: "#f5efe0",
    overlay: "rgba(128,128,128,0.3)",
    modes: {
      dark: {
        body: "#fafafa",
        heading: "fafafa",
        background: "#303030",
        overlay: "rgba(128,128,128,0.7)",
      },
    },
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    body: "-apple-system, sans-serif",
    heading: "inherit",
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.15,
  },
  radii: ["0px", "4px", "8px", "16px", "9999px"],
  shadows: {
    small: "rgba(0, 0, 0, 0.3) 0px 3px 5px",
    large: "0 0 24px rgba(0, 0, 0, .125)",
  },
  variants: {},
  text: {},
  buttons: {
    primary: {
      color: "white",
      background: "linear-gradient(133deg, #697af3 0%, #4b77be 100%)",
    },
  },
}

export const GlobalStyle = createGlobalStyle`
  body {
      color: var(--body, #2c3e50);
      background-color: var(--background, #f5efe0);
      font-family:-apple-system, sans-serif;
      -webkit-font-smoothing: antialiased;
      box-sizing: border-box;
      --overlay: rgba(128,128,128,0.3);
      --body: #2c3e50;
      --background: #f5efe0;
  }

  body.dark {
    color: var(--body, #fafafa);
    background-color: var(--background, #303030);
    --overlay: rgba(128,128,128,0.7);
    --body: #fafafa;
    --background: #303030;
  }

  *,
  *::after,
  *::before {
      margin: 0;
      padding: 0;
      box-sizing: inherit;
  }
`
