import React from "react"
import { Link } from "atoms"
import { Box, Flexbox } from "@ballchy/styled-bits"
import styled from "styled-components"
import { useStore } from "store/provider"
import { Sun } from "@styled-icons/evaicons-solid/Sun"
import { Moon } from "@styled-icons/evaicons-solid/Moon"
const Header = () => {
  const { mode, toggleTheme } = useStore()

  return (
    <Box as="header" position="absolute" width={1} top={0} zIndex={200}>
      <Flexbox as="nav" maxWidth="1040px" mx="auto" px={3} py={4}>
        <Flexbox as="ul" alignItems="center">
          <NavListItem as="li">
            <Link mr={4} fontSize={[2, 2, 3]} fontWeight="700" to="/">
              Home
            </Link>
          </NavListItem>
          <NavListItem as="li">
            <Link mr={4} fontSize={[2, 2, 3]} fontWeight="700" to="/projects">
              Projects
            </Link>
          </NavListItem>
        </Flexbox>
        <Box mx="auto" />
        <Toggle onClick={toggleTheme}>
          {mode === "light" ? <MoonIcon /> : <SunIcon />}
        </Toggle>
      </Flexbox>
    </Box>
  )
}

const NavListItem = styled(Box)`
  list-style: none;
`

const Toggle = styled(Box)`
  cursor: pointer;
`

const SunIcon = styled(Sun)`
  border-radius: 50%;
  width: 30px;
  height: 30px;
`
const MoonIcon = styled(Moon)`
  border-radius: 50%;
  width: 30px;
  height: 30px;
`

export default Header
