import React from "react"
import { Flexbox, Text, Box } from "@ballchy/styled-bits"
import styled from "styled-components"
import { Linkedin } from "@styled-icons/fa-brands/Linkedin"

const Footer = () => {
  return (
    <Flexbox
      as="footer"
      flexDirection="row-reverse"
      right={0}
      px={3}
      py={[3, 3, 4]}
    >
      <Text ml={3}>© Marco Yeung</Text>
      <Link href="https://www.linkedin.com/in/chihouyeung">
        <SocialMediaIcon>
          <Linkedin />
        </SocialMediaIcon>
      </Link>
    </Flexbox>
  )
}

const Link = styled.a`
  text-decoration: none;

  :visited,
  :active {
    color: currentColor;
  }
`
const SocialMediaIcon = styled(Box)`
  width: 24px;
  height: 24px;
`

export default Footer
